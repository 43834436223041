import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

function AboutBlock() {
  return (
    <Box p="3rem 0" position="relative" background="#1A1A1A">
      <Box
        width={{ base: '100%', md: '90%', xl: '80%' }}
        display="flex"
        margin="auto"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Fade direction="up">
          <Text
            as="h3"
            fontWeight="bold"
            fontSize={{ base: '3xl', md: '3xl', lg: '5xl' }}
            color="#FEFEFE"
            textAlign="center"
            m={{
              base: '3rem 0.5rem 0.5rem',
              md: '1rem 0.5rem 0.5rem',
              lg: '1rem 0.5rem 0.5rem',
            }}
            display="flex"
            justifyContent="center"
          >
            <Box
              width="15px"
              height="15px"
              background="#FEFEFE"
              borderRadius="10px"
              m={{
                base: '1rem 1rem 0.5rem',
                md: '1rem 2rem 0.5rem',
                lg: '1rem 2rem 0.5rem',
              }}
            ></Box>
            What do we do{' '}
            <Box
              width="15px"
              height="15px"
              background="#FEFEFE"
              borderRadius="10px"
              m={{
                base: '1rem 1rem 0.5rem',
                md: '1rem 2rem 0.5rem',
                lg: '1rem 2rem 0.5rem',
              }}
            ></Box>
          </Text>
        </Fade>
        <Text
          as="p"
          fontWeight="normal"
          fontSize="xl"
          color="#FEFEFE"
          textAlign="center"
          m={{ base: '2rem 0.5rem', md: '2rem', lg: '2rem' }}
        >
          <Fade direction="up">
            We strategically build and finance cutting-edge healthcare companies
            that are lowering costs and significantly improving healthcare
            delivery. Our experienced global AI product development and
            technology team creates AI solutions specifically for healthcare,
            optimizing existing workflows and creating new models of care and
            operations.
          </Fade>
          <br />
        </Text>
      </Box>
    </Box>
  );
}

export default AboutBlock;
