import { Box, Button, Text } from '@chakra-ui/react';
import React from 'react';

function Footer() {
  return (
    <Box background="#272727">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        maegin="0 4rem"
      >
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          margin="2rem 1rem"
        >
          <img src="/img/logo-2.png" alt="logo" width="350px" />
        </Box>
        <Box>
          <Box
            display={{
              base: 'block',
              md: 'flex',
              lg: 'flex',
            }}
            justifyContent="space-between"
          >
            <Box>
              {/* <Text
                as="p"
                fontWeight="normal"
                fontSize="md"
                color="#FEFEFE"
                textAlign="start"
                m="0 2rem 0.5rem"
              >
                Press
              </Text>
              <Text
                as="p"
                fontWeight="normal"
                fontSize="md"
                color="#FEFEFE"
                textAlign="start"
                m="0 2rem 0.5rem"
              >
                Team
              </Text> */}
              <Text
                as="p"
                fontWeight="normal"
                fontSize="md"
                color="#FEFEFE"
                textAlign="start"
                m="0 2rem 0.5rem"
              >
                Privacy Policy
              </Text>
              <Text
                as="p"
                fontWeight="normal"
                fontSize="md"
                color="#FEFEFE"
                textAlign="start"
                m="0 2rem 0.5rem"
              >
                Terms and Conditions
              </Text>
            </Box>
            <Box
              display={{
                base: 'flex',
                md: 'flex',
                lg: 'flex',
              }}
              justifyContent={{
                base: 'start',
                md: 'center',
                lg: 'center',
              }}
            >
              <Button
                background="transparent"
                color="#fefefe"
                border="1px solid #FEFEFE"
                borderRadius="20px"
                margin="2rem"
                _hover={{
                  background: 'transparent',
                }}
              >
                Contact Us
              </Button>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" margin="1rem 2rem">
            <img
              src="/img/fb.png"
              alt="fb-logo"
              style={{ margin: '0 0.5rem 0 0' }}
            />
            <img
              src="/img/instagram.png"
              alt="fb-logo"
              style={{ margin: '0 0.5rem' }}
            />{' '}
            <img
              src="/img/linkedIn.png"
              alt="fb-logo"
              style={{ margin: '0 0.5rem' }}
            />{' '}
            <img
              src="/img/twitter.png"
              alt="fb-logo"
              style={{ margin: '0 0.5rem' }}
            />
          </Box>
          <Box display="flex" justifyContent="center" margin="1rem 2rem 0.5rem">
            <Text
              as="p"
              fontWeight="normal"
              fontSize="sm"
              color="#FEFEFE"
              textAlign="center"
              m="0 2rem 0.5rem"
              display="flex"
              justifyContent="center"
            >
              © 2024 Healthpoint Ventures, All Rights Reserved.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
