import React from 'react';
import './test.css';
function Test1() {
    
  return (
    <div>
      <div class="movie">
        <div class="bg">
          <img
            src="http://7wallpapers.net/wp-content/uploads/10_Wonder-Woman.jpg"
            alt=""
          />
        </div>
        <div class="story">
          <div class="story-content">
            <h2>Wonder Woman</h2>
            <h4>
              Diana, princess of the Amazons, trained to be an unconquerable
              warrior. Raised on a sheltered island paradise, when a pilot
              crashes on their shores and tells of a massive conflict raging in
              the outside world, Diana leaves her home, convinced she can stop
              the threat. Fighting alongside man in a war to end all wars, Diana
              will discover her full powers and her true destiny.
            </h4>
          </div>
        </div>
        <div class="actor">
          <ul>
            <li>
              <h2>Director</h2>
              <h4>Patty Jenkins</h4>
            </li>
            <li>
              <h2>Writers</h2>
              <h4>Allan Heinberg, Zack Snyder</h4>
            </li>
            <li>
              <h2>Stars</h2>
              <h4>Gal Gadot, Chris Pine</h4>
            </li>
          </ul>
        </div>
      </div>

      <ul class="scroll-wrap">
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
}

export default Test1;
