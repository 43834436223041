import { Box, Text, SimpleGrid } from '@chakra-ui/react';
import React from 'react';
import { Fade } from 'react-awesome-reveal';

function ChooseUsBlock() {
  return (
    <Box
      p={{ base: '0 0 1rem', lg: '0 0 4rem' }}
      background="radial-gradient(81.96% 79.37% at 81.96% 19.66%, #0B57A9 0%, #064A93 17.5%, #032951 100%) "
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        {' '}
        <Fade direction="up">
          <Text
            as="h3"
            fontWeight="bold"
            fontSize={{ base: '3xl', md: '3xl', lg: '5xl' }}
            color="#FEFEFE"
            textAlign="center"
            m={{
              base: '1rem 0.5rem 0.5rem',
              md: '1rem 0.5rem 0.5rem',
              lg: '1rem 0.5rem 0.5rem',
            }}
            display="flex"
            justifyContent="center"
          >
            <Box
              width="15px"
              height="15px"
              background="#FEFEFE"
              borderRadius="10px"
              m={{
                base: '1rem 1rem 0.5rem',
                md: '1rem 2rem 0.5rem',
                lg: '2rem 2rem 0.5rem',
              }}
            ></Box>
            Why Choose Us
            <Box
              width="15px"
              height="15px"
              background="#FEFEFE"
              borderRadius="10px"
              m={{
                base: '1rem 1rem 0.5rem',
                md: '1rem 2rem 0.5rem',
                lg: '2rem 2rem 0.5rem',
              }}
            ></Box>
          </Text>
        </Fade>
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          m={{ base: '2rem 0', md: '2rem 0', lg: '2rem 0' }}
        >
          <SimpleGrid columns={{ base: 1, md: 2 }} gap={3}>
            <Box
              padding={{ base: '0.5rem 1rem', md: '0.5rem', lg: '0.5rem' }}
              width={{ base: '100%', md: '400px', lg: '400px' }}
            >
              <Fade direction="up">
                <img
                  src="/img/choose-1.png"
                  alt="parntner art"
                  width="100%"
                  style={{ border: '1px solid #fff', borderRadius: '12px' }}
                />
              </Fade>
            </Box>
            <Box
              padding={{ base: '0 1rem', md: '0.5rem', lg: '0.5rem' }}
              width={{ base: '100%', md: '400px', lg: '400px' }}
            >
              <Fade direction="up">
                <img
                  src="/img/choose-2.png"
                  alt="parntner art"
                  width="100%"
                  style={{ border: '1px solid #fff', borderRadius: '12px' }}
                />
              </Fade>{' '}
            </Box>
            <Box
              padding={{ base: '0.5rem 1rem', md: '0.5rem', lg: '0.5rem' }}
              width={{ base: '100%', md: '400px', lg: '400px' }}
            >
              <Fade direction="up">
                {' '}
                <img
                  src="/img/choose-3.png"
                  alt="parntner art"
                  width="100%"
                  style={{ border: '1px solid #fff', borderRadius: '12px' }}
                />
              </Fade>
            </Box>
            <Box
              padding={{ base: '0 1rem', md: '0.5rem', lg: '0.5rem' }}
              width={{ base: '100%', md: '400px', lg: '400px' }}
            >
              <Fade direction="up">
                {' '}
                <img
                  src="/img/choose-4.png"
                  alt="parntner art"
                  width="100%"
                  style={{ border: '1px solid #fff', borderRadius: '12px' }}
                />
              </Fade>{' '}
            </Box>
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
}

export default ChooseUsBlock;
